import { Box, Button, Container, Flex, Image } from '@chakra-ui/react'
import { FC } from 'preact/compat'
import { CarouselActions, CarouselItem } from '../../api/dataService'
import { MarkdownHtmlRenderer } from '../markdown/Markdown'

export const DecisionEngineCard: FC<{
  item: CarouselItem
  selectAction: (action: CarouselActions) => void
}> = ({ item, selectAction }) => {
  return (
    <Container
      variant='carousel'
      border='1px solid'
      borderColor='widget.gray.100'
      bg='unset'
    >
      <Box p='3'>
        <Box
          fontWeight='700'
          fontSize='widget.sm'
          as='h4'
          lineHeight={6}
          noOfLines={1}
        >
          <MarkdownHtmlRenderer content={item.title} />
        </Box>
        <Box
          noOfLines={12}
          maxH={120}
          overflow='auto'
          sx={{ p: { fontSize: '13px', lineHeight: '1.5' } }}
        >
          <MarkdownHtmlRenderer content={item.subtitle} />
        </Box>
      </Box>
      <Image
        alt='Carousel item'
        src={item.image}
      />
      <Flex
        direction='column'
        gap={0.5}
      >
        {item.actions?.map((action, index) => (
          <Button
            key={index}
            onClick={() => selectAction(action)}
            variant='action'
          >
            {action.title}
          </Button>
        ))}
      </Flex>
    </Container>
  )
}
