import { Button, Container, Icon } from '@chakra-ui/react'
import { FC } from 'preact/compat'
import Slider from 'react-slick'
import { CarouselActions, CarouselType } from '../../api/dataService'
import { CarouselItems, Message } from '../../context/messages'
import { ArrowLeft, ArrowRight } from '../styles/components/icons'
import { DecisionEngineCard } from './DecisionEngineCard'
import { DeviceSummaryCard } from './DeviceSummaryCard'

type ArrowProps = {
  isDark: boolean
  direction: 'left' | 'right'
  onClick: () => void
  className: string
}

interface CarouselSliderProps {
  data: Message & CarouselItems
  selectAction: (action: CarouselActions) => void
  isDark: boolean
  isDSCarousel: boolean
}

const LeftRightArrowButton: FC<ArrowProps> = ({ onClick, className, isDark, direction }) => {
  const isDisabled = className?.includes('slick-disabled')

  return (
    <Button
      variant={'arrow_button'}
      onClick={onClick}
      left={direction === 'left' ? -11 : undefined}
      right={direction === 'right' ? -1 : undefined}
      bg={isDark ? 'black' : 'white'}
      borderColor={isDark ? 'widget.primary' : 'widget.gray.800'}
      display={isDark && isDisabled ? 'none' : 'flex'}
      cursor={isDisabled ? 'unset' : 'pointer'}
      color={isDisabled ? 'gray' : isDark ? 'widget.primary' : 'black'}
    >
      <Icon
        as={direction === 'left' ? ArrowLeft : ArrowRight}
        w={12}
        h={22}
      />
    </Button>
  )
}

export const CarouselSlider: React.FC<CarouselSliderProps> = ({
  data,
  selectAction,
  isDark,
  isDSCarousel,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1.25,
    slidesToScroll: 1,
    distance: 10,
    prevArrow: (
      <LeftRightArrowButton
        direction='left'
        isDark={isDark}
        onClick={() => {}}
        className=''
      />
    ),
    nextArrow: (
      <LeftRightArrowButton
        direction='right'
        isDark={isDark}
        onClick={() => {}}
        className=''
      />
    ),
  }

  return (
    <Container w={'100%'}>
      <Slider {...settings}>
        {data.items?.map((item, index) =>
          isDSCarousel ? (
            <DeviceSummaryCard
              key={index}
              item={item}
              variant={
                data.carouselType === CarouselType.DEVICE_SUMMARY_ST ? 'st_carousel' : 'carousel'
              }
              imageSize={data.carouselType === CarouselType.DEVICE_SUMMARY_ST ? '90%' : '85%'}
            />
          ) : (
            <DecisionEngineCard
              key={index}
              item={item}
              selectAction={selectAction}
            />
          )
        )}
      </Slider>
    </Container>
  )
}
