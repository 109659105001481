import { Image } from '@chakra-ui/react'
import { useDynamicTheme } from '../styles/theme'

export const Logo = () => {
  const theme = useDynamicTheme()
  return (
    <Image
      data-testid='img_customer_logo'
      h={10}
      alt={'logo'}
      {...theme.logo.widget}
    />
  )
}
