export const MessageTypes = {
  TEXT: 'text',
  CAROUSEL: 'carousel',
  DE_CAROUSEL: 'de_carousel',
  IMAGE: 'image',
  PAYLOAD: 'payload',
  QUICK_REPLY: 'quick_reply',
} as const

export const EventTypes = {
  TEXT: 'text',
  PROACTIVE_TRIGGER: 'proactive-trigger',
  SEND_CHAT_DATA: 'send-chat-data',
  DATA_PASS: 'data-pass',
  QUICK_REPLY: 'quick_reply',
}

export const MODE = {
  BOT: 'bot',
  AGENT: 'agent',
  USER: 'user',
}

export const nodeType = {
  IMG_UPLOAD: 'img_upload',
  CONNECT_AGENT: '4250_im_connectingAgent',
}
export const CarouselType = {
  DEVICE_SUMMARY_ST: 'DS-ST',
  DECISION_ENGINE: 'DE',
  DEVICE_SUMMARY_TW: 'DS-TW',
}

export const carouselSubTypes = {
  DEFAULT: 'default',
  DE_CAROUSEL: 'de_carousel',
}

export const actionType = {
  SAY_SOMETHING: 'Say something',
  POSTBACK: 'Postback',
  OPEN_URL: 'Open URL',
}

export type BotResponse = {
  text: string
  placeholder: string
  disableFreeText: boolean
  primaryCTA: boolean
  node: string
  nodeType: string
} & (BotMsgChoice | CarouselItems | ImageType | BackgroundNavigation | PageOffNavigation)

export type BotMsgChoice = {
  type: typeof choiceType.SINGLE_CHOICE
  choices: Array<{
    title: string
    value: string
  }>
}

type CarouselItems = {
  type: typeof choiceType.CAROUSEL
  items: CarouselItem[]
  carouselType: (typeof CarouselType)[keyof typeof CarouselType]
}

type ImageType = {
  type: typeof choiceType.IMAGE
  title: string
  image: string
}

type BackgroundNavigation = {
  type: typeof choiceType.BACKGROUND_NAVIGATION
  url: string
}

type PageOffNavigation = {
  type: typeof choiceType.OFF_PAGE_REFERENCE
  url: string
}
export type CarouselItem = {
  actions: CarouselActions[]
  image: string
  markdown: boolean
  subtitle: string
  title: string
}

export type CarouselActions = {
  action: string
  title: string
  url?: string
  text?: string
  payload?: string
}

export type AuthData = {
  jwt: string
  exp: number
}

export const choiceType = {
  SINGLE_CHOICE: 'single-choice',
  BACKGROUND_NAVIGATION: 'background-navigation',
  CAROUSEL: 'carousel',
  OFF_PAGE_REFERENCE: 'off-page-reference',
  IMAGE: 'image',
} as const

export type Payload = {
  language?: 'en' | 'es'
  intent?: string
  previousBotsessionId?: string
  lpEngagementId?: string
  channel?: 'web'
}

export type ChatConfig = {
  intent?: string
}

export type BotRequestData = {
  text?: string
  payload: Payload
  type: (typeof EventTypes)[keyof typeof EventTypes]
}

export const isBasic = (a: unknown): a is BotResponse =>
  (!!(a as BotResponse).text ||
    (a as BotResponse).type == choiceType.CAROUSEL ||
    (a as BotResponse).type == choiceType.IMAGE) &&
  (a as BotResponse).node !== nodeType.CONNECT_AGENT
export const isRedirectionType = (
  a: unknown
): a is BotResponse & (BackgroundNavigation | PageOffNavigation) =>
  (a as BotResponse).type == choiceType.BACKGROUND_NAVIGATION ||
  (a as BotResponse).type == choiceType.OFF_PAGE_REFERENCE
export const isSingleChoice = (a: unknown): a is BotResponse & BotMsgChoice =>
  (a as BotResponse).type === choiceType.SINGLE_CHOICE && Array.isArray((a as BotMsgChoice).choices)

type ApiConfig = {
  botURL: string
  agentURL: string
  ccxcAuthKey: string
  botId: string
  iframeHost: string
  analyticsURL: string
  authHost: string
  baseURL: string
  paymentFormURL: string
  textRecognitionURL: string
  customer: string
  engagementStatus: string
}

type Logo = {
  src: string
}

type Translation = {
  greeting: string
  close_icon: string
  placeholder: string
  start_over: string
  agent: string
  agent_unavailable: string
  closed_conversation_agent: string
  closed_conversation_user: string
  connecting_agent: string
  failed_connect_agent: string
  waiting_in_queue: string
  open_menu: string
  send_message: string
  close_widget: string
  select_option: string
  toggle: string
  bot_icon: string
  connect: string
  expand_button: string
  collapse_button: string
}

type DelayNumbers = {
  userIdExpirationTime: number
  intervalToGetMessages: number
  historyExpirationTime: number
  redirectionDelayTime: number
}

type Colors = {
  primary: string
  secondary: string
  header: string
  headerIcon: string
  text: string
  customerText: string
  link: string
  linkHover: string
  primaryBtnHover: string
  primaryBtnHoverText: string
  secondaryBtnHover: string
  secondaryBtnText: string
  primaryBtnText: string
  secondaryBtnHoverText: string
  underLine: string
  botIcon: string
  footer: string
}

type Sizes = {
  buttonHeight: string
  buttonWidth: string
  dynamicInputWidth: string
}

type Borders = {
  primaryBorder: string
  primaryBorderHovered: string
  secondaryBorder: string
  secondaryBorderHovered: string
  headerBorderTop: string
  headerBorderBottom: string
}

type Radii = {
  radius: string
}

type Script = {
  url: string
  attribute: string
  attributeValue: string
  id: string
}

type Translations = {
  en: Translation
  es: Translation
}

type Space = {
  expandCollapseButton: string
}

type Fonts = {
  regular: string
  bold: string
  italic: string
}

type FontSizes = {
  generalFontSize: string
  placeholderSize: string
}

type SpecificFonts = {
  secondaryButtonFont: string
}

type Theme = {
  logo: Logo
  colors: Colors
  sizes: Sizes
  borders: Borders
  radii: Radii
  space: Space
  fonts: Fonts
  fontSizes: FontSizes
  specificFonts: SpecificFonts
}
export type Config = {
  apiConfig: ApiConfig
  theme: Theme
  translations: Translations
  timeoutsAndDelays: DelayNumbers
  scripts: Script[]
}
