import { createContext } from 'preact'
import { ReactNode } from 'preact/compat'
import { useContext, useEffect, useState } from 'preact/hooks'
import { CarouselItem, CarouselType, MessageTypes, MODE } from '../api/dataService'
import { getLSItem } from '../utils/localStorage'

export type TextMessage = {
  text: string
  type: typeof MessageTypes.TEXT | typeof MessageTypes.QUICK_REPLY
}

export type CarouselItems = {
  items: CarouselItem[]
  type: typeof MessageTypes.CAROUSEL
  carouselType: (typeof CarouselType)[keyof typeof CarouselType]
}

export type ImageMessage = {
  image: string
  type: typeof MessageTypes.IMAGE
  title: string
}

export type Message = { from: (typeof MODE)[keyof typeof MODE] } & (
  | TextMessage
  | CarouselItems
  | ImageMessage
)

interface MessagesContextProps {
  messages: Message[]
  addMessage: (message: Message[]) => void
}

const MessagesContext = createContext<MessagesContextProps | undefined>(undefined)

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<Message[]>([])
  useEffect(() => {
    const messagesFromLS = getLSItem<Message[]>('messages')

    if (messagesFromLS) {
      addMessage(messagesFromLS)
    }
  }, [])
  const addMessage = (message: Message[]) => {
    const messagesFromLS = getLSItem<Message[]>('messages')
    if (!messagesFromLS) {
      setMessages([])
    }
    setMessages((prevMessages) => [...prevMessages, ...message])
  }

  return (
    <MessagesContext.Provider value={{ messages, addMessage }}>{children}</MessagesContext.Provider>
  )
}

export const useMessagesContext = () => {
  const context = useContext(MessagesContext)
  if (!context) {
    throw new Error('useMessagesContext must be used within a MessagesProvider')
  }
  return context
}
