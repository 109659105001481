import { ChakraProvider } from '@chakra-ui/react'
import { useEffect, useState } from 'preact/hooks'
import { I18nextProvider } from 'react-i18next'
import './App.css'
import { useDynamicTheme } from './components/styles/theme'
import { WidgetContainer } from './components/widget/WidgetContainer'
import { ButtonsProvider } from './context/buttons'
import { InputStateProvider } from './context/inputState'
import { MessagesProvider } from './context/messages'
import { useDynamicI18n } from './i18n/i18n'
import { useAnalytics } from './utils/hooks/useAnalytics'

export const App = () => {
  useAnalytics()
  const i18n = useDynamicI18n()
  const theme = useDynamicTheme()
  const [areFontsLoaded, setAreFontsLoaded] = useState(false)

  useEffect(() => {
    // Wait until all fonts are fully loaded before rendering the app
    document.fonts.ready.then(() => {
      setAreFontsLoaded(true)
    })
  }, [])

  return (
    areFontsLoaded && (
      <div
        className='App'
        id='chat_widget_app'
      >
        <I18nextProvider i18n={i18n}>
          <ChakraProvider
            theme={theme}
            cssVarsRoot={'#chat_widget_app'}
          >
            <MessagesProvider>
              <ButtonsProvider>
                <InputStateProvider>
                  <WidgetContainer />
                </InputStateProvider>
              </ButtonsProvider>
            </MessagesProvider>
          </ChakraProvider>
        </I18nextProvider>
      </div>
    )
  )
}
