export const convertLinksToHtml = (message: string, paymentFormUrl: string) => {
  const urlRegex = /\bhttps?:\/\/[^\s"']+/g
  return message.replace(urlRegex, (url, index, fullMessage) => {
    // Ensure the URL is not inside href, src, or action attributes
    const beforeChar = fullMessage[index - 1]
    if (beforeChar && /["'=]/.test(beforeChar)) {
      return url // Return unchanged if inside an attribute
    }

    if (url.startsWith(paymentFormUrl)) {
      return ''
    }

    return `<a href="${url}">${url}</a>`
  })
}
