import { collectAnalyticsData, initializeAnalytics } from '@devrefactory-tracfone/analytics-lib'
import { useEffect } from 'preact/hooks'
import { Config } from '../../api/dataService'
import { useConfig } from '../../context/config'
import { Analytics, ANALYTICS_EVENTS } from '../analytics.interface'

const environment = import.meta.env.VITE_ANALYTICS_ENV

export const useAnalytics = () => {
  const siteConfig: Config = useConfig()
  const {
    apiConfig: { iframeHost, analyticsURL, customer },
  } = siteConfig
  const config = {
    iframeHost,
    customer,
    analyticsURL,
    environment,
    debug: true,
  }
  useEffect(() => {
    initializeAnalytics(config)
  }, [])
}

export const sendAnalyticsData = (data: Analytics) => {
  collectAnalyticsData({
    ...data,
    websession_id: sessionStorage.getItem('websession_id'),
    event: ANALYTICS_EVENTS.EVENT,
  })
}
