import { createContext } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { getLSItem } from '../utils/localStorage'

type ContextType = {
  isTextInputDisabled: boolean
  setIsTextInputDisabled: (disabled: boolean) => void
}

export const InputStateContext = createContext<ContextType | undefined>(undefined)

export function InputStateProvider({ children }: { children: preact.ComponentChildren }) {
  const [isTextInputDisabled, setIsTextInputDisabled] = useState(
    !!getLSItem<boolean>('is_input_disabled')
  )

  return (
    <InputStateContext.Provider value={{ isTextInputDisabled, setIsTextInputDisabled }}>
      {children}
    </InputStateContext.Provider>
  )
}
export const useInputStateContext = () => {
  const context = useContext(InputStateContext)
  if (!context) {
    throw new Error('useInputStateContext must be used within a InputStateProvider')
  }
  return context
}
