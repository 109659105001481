import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Text: ComponentStyleConfig = {
  variants: {
    greeting_text: {
      color: 'widget.primary',
      fontSize: 'widget.sm',
      position: 'absolute',
      w: 'auto',
      height: 7,
      p: '4px 8px',
      top: '-44px',
      bg: 'white',
      border: '1px solid',
      borderColor: 'widget.gray.300',
      borderRadius: 'widget.sm',
      right: 0,
    },
    message: {
      fontSize: 14,
      color: 'widget.text',
      ml: 4,
      a: {
        color: 'widget.link',
        _hover: {
          textDecoration: 'underline',
          color: 'widget.linkHover',
        },
      },
    },
    user_message: {
      fontSize: 14,
      color: 'widget.text',
      ml: 0,
      mr: 3,
      a: {
        color: 'widget.link',
        _hover: {
          textDecoration: 'underline',
          color: 'widget.linkHover',
        },
      },
    },
  },
}
