import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getLSItem } from '../utils/localStorage'

export type ButtonType = {
  title: string
  value: string
}

interface ButtonsContextType {
  buttons: ButtonType[]
  addButtons: (options: ButtonType[]) => void
  isPrimaryBtnEnabled: boolean
  setIsPrimaryBtnEnabled: (options: boolean) => void
}

const ButtonsContext = createContext<ButtonsContextType | undefined>(undefined)

export const ButtonsProvider = ({ children }: { children: ReactNode }) => {
  const [buttons, setButtons] = useState<ButtonType[]>([])
  const [isPrimaryBtnEnabled, setIsPrimaryBtnEnabled] = useState<boolean>(
    !!getLSItem('buttons', 'isPrimaryCTA')
  )
  useEffect(() => {
    const buttonsFromLS = getLSItem<ButtonType[]>('buttons')

    if (buttonsFromLS) {
      setButtons(buttonsFromLS)
    }
  }, [])
  const addButtons = (options: ButtonType[]) => {
    setButtons(options)
  }

  return (
    <ButtonsContext.Provider
      value={{ buttons, addButtons, isPrimaryBtnEnabled, setIsPrimaryBtnEnabled }}
    >
      {children}
    </ButtonsContext.Provider>
  )
}

export const useButtonsContext = () => {
  const context = useContext(ButtonsContext)
  if (!context) {
    throw new Error('useButtonsContext must be used within a ButtonsProvider')
  }
  return context
}
