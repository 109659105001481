import { Box, Container, Image } from '@chakra-ui/react'
import { FC } from 'preact/compat'
import { CarouselItem } from '../../api/dataService'
import { MarkdownHtmlRenderer } from '../markdown/Markdown'

export const DeviceSummaryCard: FC<{ item: CarouselItem; variant: string; imageSize: string }> = ({
  item,
  variant,
  imageSize,
}) => {
  return (
    <Container
      variant={variant}
      mr={4}
    >
      <Image
        width={imageSize}
        position='absolute'
        bottom='0'
        right='0'
        alt='Carousel item'
        src={item.image}
      />
      <Box p='3'>
        <Box
          fontWeight='700'
          fontSize='widget.sm'
          as='h4'
          lineHeight={6}
          noOfLines={1}
        >
          <MarkdownHtmlRenderer content={item.title} />
        </Box>
        <Box
          noOfLines={12}
          maxH={120}
          overflow='auto'
          sx={{ p: { fontSize: '13px', lineHeight: '1.5' } }}
        >
          <MarkdownHtmlRenderer content={item.subtitle} />
        </Box>
      </Box>
    </Container>
  )
}
