import { JSX } from 'preact/jsx-runtime'

type icons = {
  [key: string]: JSX.Element
}

type customerIcons = {
  [customerName: string]: icons
}

const defaultBotIcon: JSX.Element = (
  <svg
    viewBox='0 0 46 32.708'
    width={'36px'}
    height={'30px'}
    style={{ minWidth: '36px' }}
  >
    <g
      id='Group_88'
      data-name='Group 88'
      transform='translate(-1364 -260.69)'
    >
      <g
        id='Rectangle_55'
        data-name='Rectangle 55'
        transform='translate(1369.5 265.69)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='35'
          height='27.708'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='33'
          height='25.708'
          fill='none'
        />
      </g>
      <g
        id='Rectangle_56'
        data-name='Rectangle 56'
        transform='translate(1404 276)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='6'
          height='7'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='4'
          height='5'
          fill='none'
        />
      </g>
      <g
        id='Rectangle_57'
        data-name='Rectangle 57'
        transform='translate(1364 277)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='2'
      >
        <rect
          width='6'
          height='7'
          stroke='none'
        />
        <rect
          x='1'
          y='1'
          width='4'
          height='5'
          fill='none'
        />
      </g>
      <g
        id='Ellipse_21'
        data-name='Ellipse 21'
        transform='translate(1375 271.938)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='1.5'
      >
        <ellipse
          cx='5'
          cy='5.625'
          rx='5'
          ry='5.625'
          stroke='none'
        />
        <ellipse
          cx='5'
          cy='5.625'
          rx='4.25'
          ry='4.875'
          fill='none'
        />
      </g>
      <g
        id='Ellipse_22'
        data-name='Ellipse 22'
        transform='translate(1389 271.938)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='1.5'
      >
        <ellipse
          cx='5'
          cy='5.625'
          rx='5'
          ry='5.625'
          stroke='none'
        />
        <ellipse
          cx='5'
          cy='5.625'
          rx='4.25'
          ry='4.875'
          fill='none'
        />
      </g>
      <path
        id='Path_204'
        data-name='Path 204'
        d='M0,0H9.4'
        transform='translate(1382.299 286.058)'
        fill='none'
        stroke='currentColor'
        stroke-width='1.5'
      />
      <g
        id='Rectangle_58'
        data-name='Rectangle 58'
        transform='translate(1394 260.69)'
        stroke='currentColor'
        stroke-width='1'
      >
        <rect
          width='5'
          height='6'
          stroke='none'
        />
        <rect
          x='0.5'
          y='0.5'
          width='4'
          height='5'
          fill='none'
        />
      </g>
    </g>
  </svg>
)
const defaultBotIcon_1 = (
  <svg
    viewBox='0 0 45.141 37.963'
    width={'36px'}
    height={'30px'}
    style={{ minWidth: '36px' }}
    fill='currentColor'
  >
    <g
      id='BOT_icon'
      data-name='BOT icon'
      transform='translate(-1219.43 -2007.134)'
    >
      <g
        id='Rectangle_51'
        data-name='Rectangle 51'
        transform='translate(1224.5 2016.097)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='3'
      >
        <rect
          width='35'
          height='29'
          rx='3'
          stroke='none'
        />
        <rect
          x='1.5'
          y='1.5'
          width='32'
          height='26'
          rx='1.5'
          fill='none'
        />
      </g>
      <path
        id='Path_156'
        data-name='Path 156'
        d='M.892.883h3.57V7.961H.892Zm0,0'
        transform='translate(1258.608 2026.175)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <path
        id='Path_157'
        data-name='Path 157'
        d='M.895.883h3.57V7.961H.895Zm0,0'
        transform='translate(1220.035 2027.175)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <g
        id='Ellipse_20'
        data-name='Ellipse 20'
        transform='translate(1245 2022.304)'
        fill='#fff'
        stroke='currentColor'
        stroke-width='3'
      >
        <ellipse
          cx='4.764'
          cy='5.754'
          rx='4.764'
          ry='5.754'
          stroke='none'
        />
        <ellipse
          cx='4.764'
          cy='5.754'
          rx='3.264'
          ry='4.254'
          fill='none'
        />
      </g>
      <g
        id='Path_159'
        data-name='Path 159'
        transform='translate(1230.875 2022.304)'
        fill='#fff'
      >
        <path
          d='M 4.8955078125 9.895201683044434 C 3.023217916488647 9.895201683044434 1.499997854232788 8.012161254882812 1.499997854232788 5.697601318359375 C 1.499997854232788 3.383041381835938 3.023217916488647 1.500001311302185 4.8955078125 1.500001311302185 C 6.767797946929932 1.500001311302185 8.291017532348633 3.383041381835938 8.291017532348633 5.697601318359375 C 8.291017532348633 8.012161254882812 6.767797946929932 9.895201683044434 4.8955078125 9.895201683044434 Z'
          stroke='none'
        />
        <path
          d='M 4.8955078125 3.000000953674316 C 4.358147621154785 3.000000953674316 3.953837871551514 3.339271545410156 3.709287643432617 3.623891353607178 C 3.258527755737305 4.148511409759521 2.999997615814209 4.904351234436035 2.999997615814209 5.697601318359375 C 2.999997615814209 6.490851402282715 3.258527755737305 7.246691226959229 3.709287643432617 7.771311283111572 C 3.953837871551514 8.055931091308594 4.358147621154785 8.395200729370117 4.8955078125 8.395200729370117 C 5.432868003845215 8.395200729370117 5.837177753448486 8.055931091308594 6.081727981567383 7.771311283111572 C 6.532487869262695 7.246691226959229 6.791017532348633 6.490851402282715 6.791017532348633 5.697601318359375 C 6.791017532348633 4.904351234436035 6.532487869262695 4.148511409759521 6.081727981567383 3.623891353607178 C 5.837177753448486 3.339271545410156 5.432868003845215 3.000000953674316 4.8955078125 3.000000953674316 M 4.8955078125 9.5367431640625e-07 C 7.599217891693115 9.5367431640625e-07 9.791017532348633 2.550901412963867 9.791017532348633 5.697601318359375 C 9.791017532348633 8.844301223754883 7.599217891693115 11.39520168304443 4.8955078125 11.39520168304443 C 2.191797733306885 11.39520168304443 -1.9073486328125e-06 8.844301223754883 -1.9073486328125e-06 5.697601318359375 C -1.9073486328125e-06 2.550901412963867 2.191797733306885 9.5367431640625e-07 4.8955078125 9.5367431640625e-07 Z'
          stroke='none'
          fill='currentColor'
        />
      </g>
      <path
        id='Path_158'
        data-name='Path 158'
        d='M0,0H9.4'
        transform='translate(1237.667 2036.597)'
        fill='none'
        stroke='currentColor'
        stroke-width='3'
      />
      <g
        id='Path_164'
        data-name='Path 164'
        transform='translate(1247.569 2011.134)'
        fill='currentColor'
      >
        <path
          d='M 3.5 4.5 L 0.5 4.5 L 0.5 0.5 L 3.5 0.5 L 3.5 4.5 Z'
          stroke='none'
        />
        <path
          d='M 1 1 L 1 4 L 3 4 L 3 1 L 1 1 M 0 0 L 4 0 L 4 5 L 0 5 L 0 0 Z'
          stroke='none'
          fill='currentColor'
        />
      </g>
      <g
        id='Rectangle_53'
        data-name='Rectangle 53'
        transform='translate(1248.569 2007.134)'
        fill='currentColor'
        stroke='currentColor'
        stroke-width='1'
      >
        <rect
          width='2'
          height='5'
          stroke='none'
        />
        <rect
          x='0.5'
          y='0.5'
          width='1'
          height='4'
          fill='none'
        />
      </g>
    </g>
  </svg>
)

const defaultSendIcon: JSX.Element = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    style={{ minWidth: '20px' }}
    fill='none'
  >
    <path
      d='M18.7286 1.25L1.72864 6.25L9.72864 9.25L12.2286 18.75L18.7286 1.25Z'
      stroke='currentColor'
      stroke-width='2'
      stroke-linejoin='round'
    />
  </svg>
)

const defaultExpandIcon: JSX.Element = (
  <svg
    stroke='currentColor'
    fill='none'
    stroke-width='0'
    viewBox='0 0 24 24'
    height='20px'
    width='16px'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3 3H9V5H5V9H3V3Z'
      fill='currentColor'
    ></path>
    <path
      d='M3 21H9V19H5V15H3V21Z'
      fill='currentColor'
    ></path>
    <path
      d='M15 21H21V15H19V19H15V21Z'
      fill='currentColor'
    ></path>
    <path
      d='M21 3H15V5H19V9H21V3Z'
      fill='currentColor'
    ></path>
  </svg>
)
const defaultCollapseIcon: JSX.Element = (
  <svg
    stroke='currentColor'
    fill='none'
    stroke-width='0'
    viewBox='0 0 24 24'
    height='20px'
    width='16px'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9 9H3V7H7V3H9V9Z'
      fill='currentColor'
    ></path>
    <path
      d='M9 15H3V17H7V21H9V15Z'
      fill='currentColor'
    ></path>
    <path
      d='M21 15H15V21H17V17H21V15Z'
      fill='currentColor'
    ></path>
    <path
      d='M15 9.00012H21V7.00012H17V3.00012H15V9.00012Z'
      fill='currentColor'
    ></path>
  </svg>
)

const defualtBurgerIcon: JSX.Element = (
  <svg
    fill={'currentColor'}
    viewBox={'0 0 18 14'}
    style={{ minWidth: '18px' }}
    width='18px'
    height='16px'
  >
    <path
      id='menu_FILL0_wght400_GRAD0_opsz48_1_'
      data-name='menu_FILL0_wght400_GRAD0_opsz48 (1)'
      d='M6,26.661V24.828H24.138v1.833Zm0-6.414V18.414H24.138v1.833Zm0-6.414V12H24.138v1.833Z'
      transform='translate(-6 -12)'
      fill='currentColor'
    />
  </svg>
)

export const customerIcons: customerIcons = {
  straighttalk: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        viewBox='0 0 50 46.01'
        width={'36px'}
        height={'30px'}
        style={{ minWidth: '36px' }}
      >
        <g
          id='surface1'
          transform='translate(0 0.005)'
        >
          <path
            id='Path_87'
            data-name='Path 87'
            d='M8.93,12.383H41.07a5.335,5.335,0,0,1,5.359,5.309v23A5.332,5.332,0,0,1,41.07,46H8.93A5.332,5.332,0,0,1,3.57,40.691v-23A5.335,5.335,0,0,1,8.93,12.383Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_88'
            data-name='Path 88'
            d='M5.358,1.326H37.5a4,4,0,0,1,4.019,3.98v23a4,4,0,0,1-4.019,3.98H5.358a4,4,0,0,1-4.02-3.98v-23A4,4,0,0,1,5.358,1.326Zm0,0'
            transform='translate(3.571 12.385)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_89'
            data-name='Path 89'
            d='M44.645,23H50v8.848H44.645Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_90'
            data-name='Path 90'
            d='M.892.883h3.57V7.961H.892Zm0,0'
            transform='translate(44.643 23)'
            fill='none'
            stroke='#010101'
            stroke-width='1.777'
          />
          <path
            id='Path_91'
            data-name='Path 91'
            d='M0,23H5.355v8.848H0Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_92'
            data-name='Path 92'
            d='M.895.883h3.57V7.961H.895Zm0,0'
            transform='translate(0 23)'
            fill='none'
            stroke='#010101'
            stroke-width='1.777'
          />
          <path
            id='Path_93'
            data-name='Path 93'
            d='M27.23,6.191C27.23,2.773,26.234,0,25,0s-2.23,2.773-2.23,6.191,1,6.191,2.23,6.191S27.23,9.613,27.23,6.191Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_94'
            data-name='Path 94'
            d='M3.127,6.191c0-2.687-.4-4.863-.895-4.863S1.338,3.5,1.338,6.191s.4,4.867.895,4.867S3.127,8.879,3.127,6.191Zm0,0'
            transform='translate(22.768)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_95'
            data-name='Path 95'
            d='M37.5,27.422A4.465,4.465,0,1,1,33.035,23,4.445,4.445,0,0,1,37.5,27.422Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_96'
            data-name='Path 96'
            d='M7.589,4.422A3.125,3.125,0,1,1,4.464,1.328,3.111,3.111,0,0,1,7.589,4.422Zm0,0'
            transform='translate(28.571 23)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
          <path
            id='Path_97'
            data-name='Path 97'
            d='M0,0H11.011'
            transform='translate(19.493 36.269)'
            fill='none'
            stroke='#010101'
            stroke-width='2.679'
          />
          <path
            id='Path_98'
            data-name='Path 98'
            d='M23.215,27.422A4.465,4.465,0,1,1,18.75,23,4.445,4.445,0,0,1,23.215,27.422Zm0,0'
            fill='#fff'
          />
          <path
            id='Path_99'
            data-name='Path 99'
            d='M7.589,4.422A3.125,3.125,0,1,1,4.464,1.328,3.111,3.111,0,0,1,7.589,4.422Zm0,0'
            transform='translate(14.286 23)'
            fill='none'
            stroke='#010101'
            stroke-width='2.666'
          />
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  totalwireless: {
    sendIcon: (
      <svg
        viewBox='0 0 18 18'
        width='18px'
        height='18px'
      >
        <path
          d='M17.6891 8.73969L0.81398 0.302279C0.616898 0.203364 0.378954 0.230024 0.207036 0.369551C0.0356171 0.509327 -0.0378839 0.736308 0.0189236 0.950333L2.23018 9.24273L0.0189236 17.5354C-0.0378839 17.7494 0.0356171 17.9764 0.207036 18.1162C0.309439 18.1996 0.435512 18.2427 0.562332 18.2427C0.648042 18.2427 0.73425 18.2233 0.81398 18.1834L17.6891 9.74603C17.8797 9.6506 18 9.45601 18 9.24273C18 9.02946 17.8797 8.83487 17.6891 8.73969ZM1.42765 16.6187L3.24474 9.80533H10.1249C10.4356 9.80533 10.6875 9.55343 10.6875 9.24273C10.6875 8.93204 10.4356 8.68014 10.1249 8.68014H3.24474L1.42765 1.86698L16.1799 9.24298L1.42765 16.6187Z'
          fill='currentColor'
        />
      </svg>
    ),
    botIcon: (
      <svg
        viewBox='0 0 40 38'
        width={'36px'}
        height={'30px'}
        style={{ minWidth: '36px' }}
      >
        <path
          d='M35.6912 14.6249H35.3436V11.9742C35.3436 9.4038 33.2525 7.31217 30.6816 7.31217H29.2497V2.43757H30.4685C31.1422 2.43757 31.6873 1.8919 31.6873 1.21878C31.6873 0.545668 31.1422 0 30.4685 0H25.5934C24.9197 0 24.3746 0.545668 24.3746 1.21878C24.3746 1.8919 24.9197 2.43757 25.5934 2.43757H26.8122V7.3127H8.31836C5.74745 7.3127 3.65635 9.40434 3.65635 11.9747V14.6254H3.30882C1.48439 14.6254 0 16.1098 0 17.9342V23.4766C0 25.301 1.48439 26.7854 3.30882 26.7854H3.65635V29.4634C3.65635 32.0338 5.74745 34.1254 8.31836 34.1254H14.6254V37.7818C14.6254 38.216 14.8562 38.6177 15.2327 38.8361C15.4217 38.9454 15.6327 39.0005 15.8442 39.0005C16.0525 39.0005 16.2619 38.947 16.4488 38.8399L24.6991 34.1254H30.6822C33.2531 34.1254 35.3442 32.0338 35.3442 29.4634V26.7854H35.6917C37.5161 26.7854 39.0005 25.301 39.0005 23.4766V17.9342C39.0005 16.1098 37.5161 14.6254 35.6917 14.6254L35.6912 14.6249ZM3.30882 24.3478C2.82794 24.3478 2.43757 23.9569 2.43757 23.4766V17.9342C2.43757 17.4539 2.82794 17.063 3.30882 17.063H3.65635V24.3484H3.30882V24.3478ZM32.9061 29.4634C32.9061 30.6897 31.9085 31.6878 30.6816 31.6878H24.3746C24.1625 31.6878 23.9542 31.743 23.77 31.8485L17.0619 35.6815V32.9066C17.0619 32.2335 16.5168 31.6878 15.8431 31.6878H8.31729C7.08994 31.6878 6.09285 30.6897 6.09285 29.4634V11.9747C6.09285 10.7484 7.09047 9.75027 8.31729 9.75027H30.6811C31.9085 9.75027 32.9055 10.7484 32.9055 11.9747L32.9061 29.4634ZM36.5624 23.476C36.5624 23.9564 36.1721 24.3473 35.6912 24.3473H35.3436V17.0619H35.6912C36.1721 17.0619 36.5624 17.4528 36.5624 17.9331V23.476Z'
          fill='currentColor'
        />
        <path
          d='M18.2815 17.0626C18.2815 14.3745 16.0951 12.1875 13.4064 12.1875C10.7177 12.1875 8.53125 14.3745 8.53125 17.0626C8.53125 19.7508 10.7177 21.9378 13.4064 21.9378C16.0951 21.9378 18.2815 19.7508 18.2815 17.0626ZM10.9688 17.0626C10.9688 15.7185 12.0628 14.6251 13.4064 14.6251C14.7499 14.6251 15.844 15.7185 15.844 17.0626C15.844 18.4067 14.7499 19.5002 13.4064 19.5002C12.0628 19.5002 10.9688 18.4067 10.9688 17.0626Z'
          fill='currentColor'
        />
        <path
          d='M25.5939 12.1873C22.9052 12.1873 20.7188 14.3743 20.7188 17.0624C20.7188 19.7506 22.9052 21.9376 25.5939 21.9376C28.2826 21.9376 30.469 19.7506 30.469 17.0624C30.469 14.3743 28.2826 12.1873 25.5939 12.1873ZM25.5939 19.5C24.2504 19.5 23.1563 18.4065 23.1563 17.0624C23.1563 15.7183 24.2504 14.6249 25.5939 14.6249C26.9375 14.6249 28.0315 15.7183 28.0315 17.0624C28.0315 18.4065 26.9375 19.5 25.5939 19.5Z'
          fill='currentColor'
        />
        <path
          d='M25.5939 24.3751C24.9203 24.3751 24.3751 24.9208 24.3751 25.5939C24.3751 26.267 23.8289 26.8127 23.1563 26.8127H15.8436C15.1711 26.8127 14.6249 26.266 14.6249 25.5939C14.6249 24.9219 14.0797 24.3751 13.4061 24.3751C12.7324 24.3751 12.1873 24.9208 12.1873 25.5939C12.1873 27.6101 13.8275 29.2503 15.8436 29.2503H23.1563C25.1725 29.2503 26.8127 27.6101 26.8127 25.5939C26.8127 24.9208 26.2676 24.3751 25.5939 24.3751Z'
          fill='currentColor'
        />
      </svg>
    ),
    expandIcon: (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_2284_43)'>
          <path
            d='M7.31238 17.4374C7.31238 17.1267 7.06049 16.8748 6.74979 16.8748H2.08709C1.55639 16.8748 1.12485 16.4433 1.12485 15.9126V11.2494C1.12485 10.9387 0.872955 10.6868 0.562258 10.6868C0.251561 10.6868 -0.000335693 10.9387 -0.000335693 11.2494V15.9128C-8.65377e-05 17.0637 0.93624 18 2.08709 18H6.75004C7.06073 18 7.31238 17.7481 7.31238 17.4374ZM15.9127 18H11.2498C10.9391 18 10.6872 17.7481 10.6872 17.4374C10.6872 17.1267 10.9391 16.8748 11.2498 16.8748H15.9127C16.4434 16.8748 16.875 16.4433 16.875 15.9126V11.2494C16.875 10.9387 17.1269 10.6868 17.4376 10.6868C17.7483 10.6868 18.0002 10.9387 18.0002 11.2494V15.9128C18.0002 17.0637 17.0636 18 15.9127 18ZM17.4373 7.31222C17.1266 7.31222 16.8747 7.06032 16.8747 6.74963V2.08693C16.8747 1.55623 16.4432 1.12469 15.9125 1.12469H11.2495C10.9388 1.12469 10.6869 0.872793 10.6869 0.562096C10.6869 0.2514 10.9388 -0.000497818 11.2495 -0.000497818H15.9125C17.0633 -0.000497818 17.9997 0.935829 17.9997 2.08668V6.74938C17.9997 7.06007 17.7478 7.31197 17.4371 7.31197L17.4373 7.31222ZM6.74979 1.12494H2.08709C1.55639 1.12494 1.12485 1.55648 1.12485 2.08718V6.74988C1.12485 7.06057 0.872955 7.31247 0.562258 7.31247C0.251561 7.31247 -0.000335693 7.06057 -0.000335693 6.74988V2.08718C-0.000335693 0.936327 0.935991 0 2.08684 0H6.74979C7.06049 0 7.31238 0.251898 7.31238 0.562593C7.31238 0.873291 7.06049 1.12494 6.74979 1.12494Z'
            fill='#000035'
          />
        </g>
        <defs>
          <clipPath id='clip0_2284_43'>
            <rect
              width='18'
              height='18'
              fill='white'
              transform='matrix(1 0 0 -1 0 18)'
            />
          </clipPath>
        </defs>
      </svg>
    ),
    collapseIcon: (
      <svg
        id='Icon_Names'
        data-name='Icon Names'
        version='1.1'
        viewBox='0 0 304.88 330.88'
        width='24px'
        height='24px'
      >
        <path
          fill='currentColor'
          d='M119.75,34.69c4.51,0,8.17,3.66,8.17,8.17v67.75c0,16.72-13.6,30.32-30.32,30.32H29.86c-4.51,0-8.17-3.66-8.17-8.17s3.66-8.17,8.17-8.17h67.74c7.71,0,13.98-6.27,13.98-13.98V42.86c0-4.51,3.66-8.17,8.17-8.17Z'
        />
        <path
          fill='currentColor'
          d='M185.13,34.69c4.51,0,8.17,3.66,8.17,8.17v67.75c0,7.71,6.27,13.98,13.98,13.98h67.74c4.51,0,8.17,3.66,8.17,8.17s-3.66,8.17-8.17,8.17h-67.74c-16.72,0-30.32-13.6-30.32-30.32V42.86c0-4.51,3.66-8.17,8.17-8.17Z'
        />
        <path
          fill='currentColor'
          d='M207.28,189.96h67.74c4.51,0,8.17,3.66,8.17,8.17s-3.66,8.17-8.17,8.17h-67.74c-7.71,0-13.98,6.27-13.98,13.98v67.74c0,4.51-3.66,8.17-8.17,8.17s-8.17-3.66-8.17-8.17v-67.74c0-16.72,13.6-30.32,30.32-30.32Z'
        />
        <path
          fill='currentColor'
          d='M29.86,189.96h67.74c16.72,0,30.32,13.6,30.32,30.32v67.74c0,4.51-3.66,8.17-8.17,8.17s-8.17-3.66-8.17-8.17v-67.74c0-7.71-6.27-13.98-13.98-13.98H29.86c-4.51,0-8.17-3.66-8.17-8.17s3.66-8.17,8.17-8.17Z'
        />
      </svg>
    ),
    burgerIcon: (
      <svg
        width='22'
        height='18'
        viewBox='0 0 22 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M22.2222 0V1.67407H0V0H22.2222ZM0 18.3556H22.2222V16.6667H0V18.3556ZM0 10.0148H22.2222V8.34074H0V10.0148Z'
          fill='#1A1C35'
        />
      </svg>
    ),
  },

  wfm: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={'36px'}
        height={'30px'}
        style={{ minWidth: '36px' }}
        viewBox='0 0 28 26'
      >
        <g transform='translate(-1136.181 -287)'>
          <g
            transform='translate(1138.181 293.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <rect
              width='24'
              height='19'
              rx='3'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='22.5'
              height='17.5'
              rx='2.25'
              fill='none'
            />
          </g>
          <g
            transform='translate(1161.181 299.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1'
          >
            <rect
              width='3'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='4'
              fill='none'
            />
          </g>
          <g
            transform='translate(1136.181 299.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1'
          >
            <rect
              width='3'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='4'
              fill='none'
            />
          </g>
          <g
            transform='translate(1151.431 298.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <ellipse
              cx='3.222'
              cy='3.625'
              rx='3.222'
              ry='3.625'
              stroke='none'
            />
            <ellipse
              cx='3.222'
              cy='3.625'
              rx='2.472'
              ry='2.875'
              fill='none'
            />
          </g>
          <path
            d='M0,0H6.167'
            transform='translate(1147.098 308.463)'
            fill='none'
            stroke='#010101'
            stroke-width='1.5'
          />
          <g
            transform='translate(1142.627 298.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <ellipse
              cx='3.277'
              cy='3.625'
              rx='3.277'
              ry='3.625'
              stroke='none'
            />
            <ellipse
              cx='3.277'
              cy='3.625'
              rx='2.527'
              ry='2.875'
              fill='none'
            />
          </g>
          <g
            transform='translate(1155 290)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='3'
              height='4'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='3'
              fill='none'
            />
          </g>
          <g
            transform='translate(1156 287)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='1'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              height='4'
              fill='none'
            />
          </g>
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  tracfone: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={'36px'}
        height={'30px'}
        style={{ minWidth: '36px' }}
        viewBox='0 0 51.141 39.963'
        fill='black'
      >
        <g
          id='Group_53'
          data-name='Group 53'
          transform='translate(-1114.861 -2071)'
        >
          <g
            id='Rectangle_51'
            data-name='Rectangle 51'
            transform='translate(1119.931 2079.963)'
            fill='#fff'
            stroke='black'
            stroke-width='3'
          >
            <rect
              width='41'
              height='31'
              rx='3'
              stroke='none'
            />
            <rect
              x='1.5'
              y='1.5'
              width='38'
              height='28'
              rx='1.5'
              fill='none'
            />
          </g>
          <path
            id='Path_156'
            data-name='Path 156'
            d='M.892.883h3.57V7.961H.892Zm0,0'
            transform='translate(1160.039 2091.041)'
            fill='none'
            stroke='black'
            stroke-width='3'
          />
          <path
            id='Path_157'
            data-name='Path 157'
            d='M.895.883h3.57V7.961H.895Zm0,0'
            transform='translate(1115.466 2091.041)'
            fill='none'
            stroke='black'
            stroke-width='3'
          />
          <g
            id='Ellipse_20'
            data-name='Ellipse 20'
            transform='translate(1143.653 2086.418)'
            fill='#fff'
            stroke='black'
            stroke-width='3'
          >
            <ellipse
              cx='4.764'
              cy='5.754'
              rx='4.764'
              ry='5.754'
              stroke='none'
            />
            <ellipse
              cx='4.764'
              cy='5.754'
              rx='3.264'
              ry='4.254'
              fill='none'
            />
          </g>
          <g
            id='Path_159'
            data-name='Path 159'
            transform='translate(1128.181 2086.474)'
            fill='#fff'
          >
            <path
              d='M 4.8955078125 9.895201683044434 C 3.023217916488647 9.895201683044434 1.499997854232788 8.012161254882812 1.499997854232788 5.697601318359375 C 1.499997854232788 3.383041381835938 3.023217916488647 1.500001311302185 4.8955078125 1.500001311302185 C 6.767797946929932 1.500001311302185 8.291017532348633 3.383041381835938 8.291017532348633 5.697601318359375 C 8.291017532348633 8.012161254882812 6.767797946929932 9.895201683044434 4.8955078125 9.895201683044434 Z'
              stroke='none'
            />
            <path
              d='M 4.8955078125 3.000000953674316 C 4.358147621154785 3.000000953674316 3.953837871551514 3.339271545410156 3.709287643432617 3.623891353607178 C 3.258527755737305 4.148511409759521 2.999997615814209 4.904351234436035 2.999997615814209 5.697601318359375 C 2.999997615814209 6.490851402282715 3.258527755737305 7.246691226959229 3.709287643432617 7.771311283111572 C 3.953837871551514 8.055931091308594 4.358147621154785 8.395200729370117 4.8955078125 8.395200729370117 C 5.432868003845215 8.395200729370117 5.837177753448486 8.055931091308594 6.081727981567383 7.771311283111572 C 6.532487869262695 7.246691226959229 6.791017532348633 6.490851402282715 6.791017532348633 5.697601318359375 C 6.791017532348633 4.904351234436035 6.532487869262695 4.148511409759521 6.081727981567383 3.623891353607178 C 5.837177753448486 3.339271545410156 5.432868003845215 3.000000953674316 4.8955078125 3.000000953674316 M 4.8955078125 9.5367431640625e-07 C 7.599217891693115 9.5367431640625e-07 9.791017532348633 2.550901412963867 9.791017532348633 5.697601318359375 C 9.791017532348633 8.844301223754883 7.599217891693115 11.39520168304443 4.8955078125 11.39520168304443 C 2.191797733306885 11.39520168304443 -1.9073486328125e-06 8.844301223754883 -1.9073486328125e-06 5.697601318359375 C -1.9073486328125e-06 2.550901412963867 2.191797733306885 9.5367431640625e-07 4.8955078125 9.5367431640625e-07 Z'
              stroke='none'
              fill='black'
            />
          </g>
          <path
            id='Path_158'
            data-name='Path 158'
            d='M0,0H9.4'
            transform='translate(1136.098 2101.463)'
            fill='none'
            stroke='black'
            stroke-width='3'
          />
          <g
            id='Rectangle_52'
            data-name='Rectangle 52'
            transform='translate(1150 2075)'
            stroke='black'
            stroke-width='1'
          >
            <rect
              width='4'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='3'
              height='4'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_53'
            data-name='Rectangle 53'
            transform='translate(1151 2071)'
            fill='black'
            stroke='black'
            stroke-width='1'
          >
            <rect
              width='2'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='1'
              height='4'
              fill='none'
            />
          </g>
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  simplemobile: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  gosmart: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='30'
        style={{ minWidth: '36px' }}
        viewBox='0 0 94.000000 72.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          transform='translate(0.000000,72.000000) scale(0.100000,-0.100000)'
          fill='#000000'
          stroke='none'
        >
          <path
            d='M590 660 l0 -60 -200 0 c-268 0 -288 -8 -298 -125 l-5 -65 -43 0 -44
0 0 -80 0 -80 45 0 45 0 0 -81 c0 -92 17 -140 56 -158 35 -15 613 -15 648 0
39 18 56 66 56 158 l0 81 45 0 45 0 0 80 0 80 -44 0 -43 0 -6 67 c-4 56 -9 71
-31 90 -14 12 -42 25 -61 29 l-35 6 0 59 0 59 -65 0 -65 0 0 -60z m192 -127
c16 -15 18 -35 18 -230 0 -129 -4 -222 -10 -234 -10 -18 -24 -19 -320 -19
-296 0 -310 1 -320 19 -6 12 -10 105 -10 232 0 184 2 215 17 231 15 17 38 18
311 18 261 0 298 -2 314 -17z m-692 -203 c0 -49 -1 -50 -30 -50 -29 0 -30 1
-30 50 0 49 1 50 30 50 29 0 30 -1 30 -50z m820 0 c0 -49 -1 -50 -30 -50 -29
0 -30 1 -30 50 0 49 1 50 30 50 29 0 30 -1 30 -50z'
          />
          <path
            d='M279 455 c-30 -16 -59 -70 -59 -110 0 -94 97 -156 164 -104 73 57 73
151 0 208 -31 24 -68 26 -105 6z m96 -39 c34 -34 41 -71 21 -112 -22 -46 -59
-63 -94 -43 -58 32 -66 119 -15 159 34 26 59 25 88 -4z'
          />
          <path
            d='M556 449 c-32 -25 -56 -69 -56 -103 0 -61 57 -126 110 -126 56 0 110
61 110 125 0 94 -97 156 -164 104z m101 -33 c23 -20 28 -32 28 -71 0 -39 -5
-51 -28 -71 -35 -30 -59 -30 -94 0 -23 20 -28 32 -28 71 0 39 5 51 28 71 15
13 37 24 47 24 10 0 32 -11 47 -24z'
          />
          <path
            d='M377 154 c-19 -19 1 -24 94 -24 76 0 100 3 97 13 -5 13 -178 23 -191
11z'
          />
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  pageplus: {
    sendIcon: defaultSendIcon,
    botIcon: defaultBotIcon_1,
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
  safelink: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='30'
        viewBox='0 0 28 25.963'
        style={'min-width: 36px'}
      >
        <g
          id='Bot_icon_small'
          data-name='Bot icon small'
          transform='translate(-1136.181 -287)'
        >
          <g
            id='Rectangle_16'
            data-name='Rectangle 16'
            transform='translate(1138.181 293.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <rect
              width='24'
              height='19'
              rx='3'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='22.5'
              height='17.5'
              rx='2.25'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_19'
            data-name='Rectangle 19'
            transform='translate(1161.181 299.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1'
          >
            <rect
              width='3'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='4'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_20'
            data-name='Rectangle 20'
            transform='translate(1136.181 299.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1'
          >
            <rect
              width='3'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='4'
              fill='none'
            />
          </g>
          <g
            id='Ellipse_10'
            data-name='Ellipse 10'
            transform='translate(1151.431 298.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <ellipse
              cx='3.222'
              cy='3.625'
              rx='3.222'
              ry='3.625'
              stroke='none'
            />
            <ellipse
              cx='3.222'
              cy='3.625'
              rx='2.472'
              ry='2.875'
              fill='none'
            />
          </g>
          <path
            id='Path_7'
            data-name='Path 7'
            d='M0,0H6.167'
            transform='translate(1147.098 308.463)'
            fill='none'
            stroke='#010101'
            stroke-width='1.5'
          />
          <g
            id='Ellipse_12'
            data-name='Ellipse 12'
            transform='translate(1142.627 298.963)'
            fill='#fff'
            stroke='#010101'
            stroke-width='1.5'
          >
            <ellipse
              cx='3.277'
              cy='3.625'
              rx='3.277'
              ry='3.625'
              stroke='none'
            />
            <ellipse
              cx='3.277'
              cy='3.625'
              rx='2.527'
              ry='2.875'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_44'
            data-name='Rectangle 44'
            transform='translate(1155 290)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='3'
              height='4'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='2'
              height='3'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_45'
            data-name='Rectangle 45'
            transform='translate(1156 287)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='1'
              height='5'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              height='4'
              fill='none'
            />
          </g>
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },

  net10wireless: {
    sendIcon: defaultSendIcon,
    botIcon: (
      <svg
        viewBox='0 0 42 34.708'
        width='36'
        height='30'
        style={{ minWidth: '36px' }}
      >
        <g
          id='BOT_icon_'
          data-name='BOT icon '
          transform='translate(-1366 -258.69)'
        >
          <g
            id='Rectangle_55'
            data-name='Rectangle 55'
            transform='translate(1369.5 265.69)'
            fill='#fff'
            stroke='currentColor'
            stroke-width='2'
          >
            <rect
              width='35'
              height='27.708'
              stroke='none'
            />
            <rect
              x='1'
              y='1'
              width='33'
              height='25.708'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_56'
            data-name='Rectangle 56'
            transform='translate(1403 276)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <rect
              width='5'
              height='6.19'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='3.5'
              height='4.69'
              fill='none'
            />
          </g>
          <g
            id='Rectangle_57'
            data-name='Rectangle 57'
            transform='translate(1366 276)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <rect
              width='5'
              height='6.188'
              stroke='none'
            />
            <rect
              x='0.75'
              y='0.75'
              width='3.5'
              height='4.688'
              fill='none'
            />
          </g>
          <g
            id='Ellipse_21'
            data-name='Ellipse 21'
            transform='translate(1377 272.938)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <circle
              cx='4.5'
              cy='4.5'
              r='4.5'
              stroke='none'
            />
            <circle
              cx='4.5'
              cy='4.5'
              r='3.75'
              fill='none'
            />
          </g>
          <g
            id='Ellipse_22'
            data-name='Ellipse 22'
            transform='translate(1389 272.938)'
            fill='#fff'
            stroke='#231f20'
            stroke-width='1.5'
          >
            <circle
              cx='4.5'
              cy='4.5'
              r='4.5'
              stroke='none'
            />
            <circle
              cx='4.5'
              cy='4.5'
              r='3.75'
              fill='none'
            />
          </g>
          <path
            id='Path_204'
            data-name='Path 204'
            d='M0,0H9.4'
            transform='translate(1382.299 286.058)'
            fill='none'
            stroke='#000'
            stroke-width='1.5'
          />
          <g
            id='Rectangle_58'
            data-name='Rectangle 58'
            transform='translate(1399 258.69)'
            stroke='#000'
            stroke-width='1'
          >
            <rect
              width='2'
              height='8'
              stroke='none'
            />
            <rect
              x='0.5'
              y='0.5'
              width='1'
              height='7'
              fill='none'
            />
          </g>
        </g>
      </svg>
    ),
    expandIcon: defaultExpandIcon,
    collapseIcon: defaultCollapseIcon,
    burgerIcon: defualtBurgerIcon,
  },
}
