import { Config } from '../../api/dataService'
import defaultConfig from '../../configs/defaultConfig.json'

export const fetchConfig = async (url: string): Promise<Config> => {
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Failed to fetch configuration')
    }
    return await response.json()
  } catch (error) {
    console.error('Error fetching configuration:', error)
    return defaultConfig // fallback to default configuration
  }
}
