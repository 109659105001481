import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import sanitizeHtml from 'sanitize-html'
import { useConfig } from '../../context/config'
import { convertLinksToHtml } from '../../utils/helper'

export const MarkdownHtmlRenderer: React.FC<{ content: string }> = ({ content }) => {
  const {
    apiConfig: { paymentFormUrl },
  } = useConfig()
  const sanitizedContent = sanitizeHtml(content)
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]} // Allows rendering raw HTML within Markdown
    >
      {convertLinksToHtml(sanitizedContent, paymentFormUrl)}
    </ReactMarkdown>
  )
}
