import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { useConfig } from '../context/config'

const languageFromHTML = document.documentElement.lang.includes('es') ? 'es' : 'en'

export const useDynamicI18n = () => {
  const config = useConfig()
  const {
    translations: { en, es },
  } = config

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      lng: languageFromHTML,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: {
            ...(en as object),
          },
        },
        es: {
          translation: {
            ...(es as object),
          },
        },
      },
    })
  return i18n
}
