import { Container, Drawer, DrawerContent, DrawerHeader, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatConfig } from '../../api/dataService'
import { useBot } from '../../utils/hooks/useBot'
import { getLSItem } from '../../utils/localStorage'
import { Header } from '../header/Header'
import { Widget } from './Widget'
import { WidgetIcon } from './WidgetIcon'
export const WidgetContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)
  const [intent, setIntent] = useState<string>()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isWidgetLoading, setIsWidgetLoading] = useState(false)
  const [language, setLanguage] = useState<string>('')
  const { i18n } = useTranslation()

  const { changeBotLanguage } = useBot()

  const handleOpenEvent = () => {
    window.postMessage({ name: 'refactory:chat-widget:webchatOpened' }, window.location.origin)
    onOpen()
  }

  const handleCloseEvent = () => {
    window.postMessage({ name: 'refactory:chat-widget:webchatClosed' }, window.location.origin)
    onClose()
  }

  const toggleWidget = () => {
    setIsExpanded(false)
    if (isOpen) {
      handleCloseEvent()
    } else {
      handleOpenEvent()
    }
  }

  const toggleExpandCollapse = () => {
    setIsExpanded((prev) => !prev)
  }

  const clearIntent = () => {
    setIntent(undefined)
  }

  useEffect(() => {
    const handleDispatchWebsessionIdEvent = (event: CustomEvent<string>) => {
      sessionStorage.setItem('websession_id', event.detail)
    }

    const handleLanguageChange = (event: CustomEvent<string>) => {
      setLanguage(event.detail)
    }

    window.addEventListener('refactory:chat-widget:open', handleOpenEvent)
    window.addEventListener('switchLang', handleLanguageChange)
    window.addEventListener('refactory:chat-widget:close', handleCloseEvent)
    window.addEventListener('refactory:dispatchSessionId', handleDispatchWebsessionIdEvent)

    return () => {
      window.removeEventListener('refactory:chat-widget:open', handleOpenEvent)
      window.removeEventListener('switchLang', handleLanguageChange)
      window.removeEventListener('refactory:chat-widget:close', handleCloseEvent)
      window.removeEventListener('refactory:dispatchSessionId', handleDispatchWebsessionIdEvent)
    }
  }, [onOpen, onClose])

  useEffect(() => {
    window.postMessage({ name: 'refactory:chat-widget:initialization' }, window.location.origin)
    const queryParams = new URLSearchParams(window.location.search)
    const isShowBot = queryParams.get('showBot') === 'true'

    if (isShowBot) {
      toggleWidget()
    }
    window.chatWidget = {
      open: (chatConfig: ChatConfig) => {
        const { intent } = chatConfig || {}
        if (intent) setIntent(intent)
        window.dispatchEvent(new CustomEvent('refactory:chat-widget:open'))
      },
      close: () => window.dispatchEvent(new CustomEvent('refactory:chat-widget:close')),
    }

    return () => {
      delete window.chatWidget
    }
  }, [])

  useEffect(() => {
    const changeLang = async () => {
      const prevLanguage = getLSItem<'es' | 'en'>('language')
      const newLang = language || (document.documentElement.lang.includes('es') ? 'es' : 'en')
      if (prevLanguage !== newLang) {
        await cleanUpBotAndSetLang(newLang)
      }
    }
    changeLang()
  }, [language])

  const cleanUpBotAndSetLang = async (lang: string) => {
    i18n.changeLanguage(lang)
    if (getLSItem('engagement_id')) {
      return // Do not send proactive-trigger when the agent is connected.
    }
    setIsWidgetLoading(true)
    await changeBotLanguage(lang)
    setIsWidgetLoading(false)
  }

  return (
    <Container variant='widget_container'>
      {isOpen && (
        <Drawer
          placement='bottom'
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          trapFocus={false}
          lockFocusAcrossFrames={true}
          size={isExpanded ? 'full' : 'widget.md'}
          blockScrollOnMount={false}
        >
          <div id='chat_widget_app'>
            <DrawerContent
              data-testid='container_bot'
              w={['100%', isExpanded ? '100%' : 'widget.widthWidget']}
              height={['100%', isExpanded ? '100%' : 'widget.heightWidget']}
              top={['0', 'unset']}
              left={['0', 'auto !important']}
              right={['0', '10px']}
            >
              <DrawerHeader p={0}>
                <Header onClose={toggleWidget} />
              </DrawerHeader>
              <Widget
                toggleExpandCollapse={toggleExpandCollapse}
                intent={intent}
                language={language}
                clearIntent={clearIntent}
                isWidgetLoading={isWidgetLoading}
              />
            </DrawerContent>
          </div>
        </Drawer>
      )}
      <WidgetIcon onClick={toggleWidget} />
    </Container>
  )
}
