import { Box, Button, Container, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'

import { v4 as uuidv4 } from 'uuid'
import {
  actionType,
  CarouselActions,
  CarouselType,
  Config,
  EventTypes,
  MessageTypes,
  MODE,
  Payload,
} from '../../api/dataService'
import { textRecognition } from '../../api/direct'
import { ButtonType, useButtonsContext } from '../../context/buttons'
import { useConfig } from '../../context/config'
import { useInputStateContext } from '../../context/inputState'
import { useMessagesContext } from '../../context/messages'
import { ANALYTICS_EVENTS } from '../../utils/analytics.interface'
import { useAgent } from '../../utils/hooks/useAgent'
import { sendAnalyticsData } from '../../utils/hooks/useAnalytics'
import { useBot } from '../../utils/hooks/useBot'
import { captureImage } from '../../utils/imageCapturer'
import { getLSItem, removeLSItem, setLSItem } from '../../utils/localStorage'
import { CarouselSlider } from '../carousel/CarouselSlider'
import { GetIcon } from '../dialog/DialogAuthorIcon'
import { Footer } from '../dialog/Footer'
import { PaymentButton } from '../dialog/PaymentButton'
import { MarkdownHtmlRenderer } from '../markdown/Markdown'

const BUTTON_TYPE = {
  START_OVER: 'Start over',
  QUIT: 'quit',
}

interface ComponentProps {
  intent?: string
  language: string
  isWidgetLoading: boolean
  clearIntent: () => void
  toggleExpandCollapse: () => void
}

export const Widget = ({
  intent,
  language,
  isWidgetLoading,
  clearIntent,
  toggleExpandCollapse,
}: ComponentProps) => {
  const { t } = useTranslation()
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const answersRef = useRef<HTMLDivElement>(null)
  const lastMessageRef = useRef<HTMLDivElement>(null)
  const lastButtonsRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    isAgentMode,
    sendMessage: sendMessageToAgent,
    closeAgent,
    handleAgent,
    fetchAgentMessages,
  } = useAgent()
  const {
    sendMessage: sendMessageToBot,
    placeholder,
    isAgentTriggered,
    setIsAgentTriggered,
    isImageUploadEnabled,
    changeBotLanguage,
  } = useBot()
  const { isTextInputDisabled } = useInputStateContext()
  const {
    apiConfig: { paymentFormURL, botId, textRecognitionURL },
    timeoutsAndDelays: { userIdExpirationTime, intervalToGetMessages, historyExpirationTime },
  }: Config = useConfig()
  const { addMessage, messages } = useMessagesContext()
  const { buttons, isPrimaryBtnEnabled } = useButtonsContext()

  const sendMessage = useCallback(
    async (
      msg: string,
      { isMessageShown } = { isMessageShown: false },
      type: (typeof EventTypes)[keyof typeof EventTypes] = EventTypes.TEXT,
      payload = {}
    ) => {
      setIsLoading(true)
      const userId: string = getLSItem('user_id') || ''
      // Handle session expiration by checking for the presence of `user_id`
      if (!userId) {
        startNewSession()
        return
      }
      isAgentMode
        ? await sendMessageToAgent(msg)
        : await sendMessageToBot({ text: msg, type, payload }, { isMessageShown })
      clearIntent()
      setIsLoading(false)
    },
    [isAgentMode]
  )

  const changeBotLang = async () => {
    const newLang = language || document.documentElement.lang.includes('es') ? 'es' : 'en'
    const prevLang = getLSItem('language')

    if (newLang !== prevLang) {
      await changeBotLanguage(newLang)
      setIsLoading(false)
      return true
    }

    return false
  }

  const handleBack = async () => {
    setIsLoading(true)
    const userId: string = getLSItem('user_id') || ''
    // Handle session expiration by checking for the presence of `user_id`
    if (!userId) {
      startNewSession()
      return
    } else {
      await closeAgent()
      const langChanged = await changeBotLang()
      if (langChanged) return
    }

    sendAnalyticsData({
      name: ANALYTICS_EVENTS.RESET,
      event_category: ANALYTICS_EVENTS.BUTTON_CLICK,
    })
    await sendMessageToBot(
      { text: BUTTON_TYPE.START_OVER, type: EventTypes.TEXT, payload: {} },
      { isMessageShown: true }
    )

    setIsLoading(false)
  }

  const handleOptionSelect = async (button: ButtonType) => {
    setIsLoading(true)
    if (button.value === BUTTON_TYPE.START_OVER) {
      await closeAgent()
      const langChanged = await changeBotLang()
      if (langChanged) return
    }
    sendAnalyticsData({
      name: button.title,
      event_category: ANALYTICS_EVENTS.BUTTON_CLICK,
    })
    await sendMessage(button.value, { isMessageShown: true }, EventTypes.QUICK_REPLY, {})
    setIsLoading(false)
  }

  const scrollIntoView = useCallback(() => {
    // Scroll to the bottom of the chat when messages update
    if (!lastButtonsRef.current && !lastMessageRef.current) return

    const targetElement = lastButtonsRef.current || lastMessageRef.current
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [])

  const handleLinkClick = (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement
    const url = target.href
    sendAnalyticsData({
      event_category: ANALYTICS_EVENTS.LINK_CLICK,
      url,
    })
  }

  const getPaymentButton = (text: string) => {
    const isPaymentForm = text.includes(paymentFormURL)
    if (isPaymentForm) {
      return <PaymentButton />
    }
    return <></>
  }

  const initBot = async (intent?: string) => {
    const text = 'hi'
    const queryParams = new URLSearchParams(window.location.search)
    const previousBotsessionId = queryParams.get('previousBotsessionId')
    const languageCode = language || document.documentElement.lang.includes('es') ? 'es' : 'en'

    const payload: Payload = { language: languageCode, channel: 'web' }

    if (intent) payload.intent = intent
    if (previousBotsessionId) payload.previousBotsessionId = previousBotsessionId
    if (language === 'es' || language === 'en') payload.language = language
    await sendMessageToBot(
      { text, type: EventTypes.PROACTIVE_TRIGGER, payload },
      { isMessageShown: false }
    )
  }

  const startNewSession = async (intent?: string) => {
    setIsLoading(true)
    const existingUserId = getLSItem('user_id')
    const newUserId = existingUserId || uuidv4()
    setIsLoading(true)

    if (existingUserId !== newUserId) {
      if (isAgentMode) {
        await closeAgent()
      }
      setLSItem('user_id', newUserId, userIdExpirationTime)
    }

    if (intent || existingUserId !== newUserId) {
      await initBot(intent)
    }
    setIsLoading(false)
  }

  const selectAction = async (actionItem: CarouselActions) => {
    switch (actionItem.action) {
      case actionType.OPEN_URL:
        window.open(actionItem.url, '_blank')
        break
      case actionType.POSTBACK:
        await sendMessage(actionItem.payload || '', { isMessageShown: true }, MessageTypes.PAYLOAD)
        break
      case actionType.SAY_SOMETHING:
        await sendMessage(actionItem.text || '', { isMessageShown: true }, MessageTypes.TEXT)
        break
    }
  }

  const handleImageCapture = async () => {
    await captureImage(sendPhoto)
  }

  const sendPhoto = async (base64: string) => {
    const payload = {
      image: base64,
    }
    try {
      const res: { text: string } = await textRecognition(payload, textRecognitionURL)
      sendMessage(res.text, { isMessageShown: true }, EventTypes.TEXT)
    } catch (error) {
      console.error('Error uploading photo:', error)
    }
  }

  /**
   * Stores the `messages` and `buttons` in storage for ${historyExpirationTime} hours
   */
  useEffect(() => {
    scrollIntoView()
    setLSItem('messages', { messages: JSON.stringify(messages) }, historyExpirationTime)
  }, [messages])

  useEffect(() => {
    const reInitBot = async () => {
      if (!messages.length && getLSItem('user_id')) {
        setIsLoading(true)
        await initBot()
        setIsLoading(false)
      }
    }
    reInitBot()
  }, [])

  useEffect(() => {
    scrollIntoView()
    setLSItem(
      'buttons',
      { buttons: JSON.stringify(buttons), isPrimaryCTA: isPrimaryBtnEnabled },
      historyExpirationTime
    )
  }, [buttons])

  useEffect(() => {
    if (isAgentMode) return
    if (!intent) return
    if (!getLSItem('user_id')) {
      startNewSession(intent)
    } else {
      initBot(intent)
    }
    return () => {
      clearIntent()
    }
  }, [intent])

  /**
   *Fetch agent messages every 5 seconds if an engagement ID exists, updating the message state.
   */
  useEffect(() => {
    const updateMessages = async () => {
      const newMessages = await fetchAgentMessages()
      if (newMessages.length > 0) {
        addMessage([...newMessages])
      }
    }
    if (isAgentMode) {
      updateMessages()
      const intervalId = setInterval(updateMessages, intervalToGetMessages)
      return () => clearInterval(intervalId)
    }
  }, [isAgentMode])

  useEffect(() => {
    if (isAgentTriggered) {
      const connectToAgent = async () => {
        // TODO: temporary send another call  `DATA_PASS` until the agent widget is confirmed by the customer QA
        await sendMessageToBot({ type: EventTypes.DATA_PASS, payload: {} })
        await handleAgent()
        // Request the Bot to send chat data to the agent
        await sendMessageToBot(
          {
            type: EventTypes.SEND_CHAT_DATA,
            payload: {
              lpEngagementId: getLSItem('engagement_id') || '',
            },
          },
          { isMessageShown: false }
        )
      }
      connectToAgent()
    }

    return () => {
      setIsAgentTriggered(false)
    }
  }, [isAgentTriggered])

  // Add event listeners to links to handle analytics for link clicks
  useEffect(() => {
    if (messageContainerRef.current) {
      const links = messageContainerRef.current.querySelectorAll('a')

      links.forEach((link) => {
        link.addEventListener('click', handleLinkClick)
      })

      return () => {
        links.forEach((link) => {
          link.removeEventListener('click', handleLinkClick)
        })
      }
    }
  }, [messageContainerRef.current])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const prevBotId = getLSItem('botId')
    const currentBotId = queryParams.get('botId') || botId
    const userId = getLSItem('user_id')

    if (!userId || prevBotId !== currentBotId) {
      removeLSItem('messages')
      removeLSItem('buttons')
      removeLSItem('user_id')
      startNewSession()
    }
  }, [])

  useEffect(() => {
    const container = messageContainerRef.current
    if (!container) return

    const observer = new MutationObserver(() => {
      scrollIntoView()
    })

    observer.observe(container, {
      childList: true, // Observes direct children addition/removal
      subtree: true, // Observes deeper changes in the DOM tree
    })

    return () => observer.disconnect()
  }, [scrollIntoView])

  useEffect(() => {
    sendAnalyticsData({
      event_category: ANALYTICS_EVENTS.OPEN_WIDGET,
    })

    return () => {
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.CLOSE_WIDGET,
      })
    }
  }, [])

  return (
    <Container
      variant='widget_wrapper'
      ref={messageContainerRef}
    >
      <Container
        variant={'answers_wrapper'}
        overflowX={'hidden'}
        ref={answersRef}
      >
        {messages.map((msg, index) =>
          msg.type === MessageTypes.CAROUSEL ? (
            <Flex
              key={index}
              alignItems='flex-end'
              ml={msg.from === MODE.USER ? 7 : 0}
              flexDirection={msg.from === MODE.USER ? 'row-reverse' : 'row'}
            >
              {msg.carouselType === CarouselType.DECISION_ENGINE && (
                <GetIcon from={msg.from}></GetIcon>
              )}
              <CarouselSlider
                isDark={msg.carouselType === CarouselType.DEVICE_SUMMARY_ST}
                isDSCarousel={
                  msg.carouselType === CarouselType.DEVICE_SUMMARY_ST ||
                  msg.carouselType === CarouselType.DEVICE_SUMMARY_TW
                }
                selectAction={selectAction}
                key={index}
                data={msg}
              />
            </Flex>
          ) : msg.type === MessageTypes.IMAGE ? (
            <Image
              src={msg.image}
              alt={msg.title}
              w={'100%'}
              h={'100%'}
            ></Image>
          ) : (
            <Box>
              <Flex
                key={index}
                alignItems='flex-end'
                ml={msg.from === MODE.USER ? 7 : 0}
                flexDirection={msg.from === MODE.USER ? 'row-reverse' : 'row'}
              >
                <GetIcon from={msg.from}></GetIcon>
                <Text
                  as='span'
                  variant={msg.from === MODE.USER ? 'user_message' : 'message'}
                  ref={index === messages.length - 1 ? lastMessageRef : undefined}
                >
                  <MarkdownHtmlRenderer content={msg.text} />
                </Text>
              </Flex>
              {getPaymentButton(msg.text)}
            </Box>
          )
        )}
        {isLoading || isWidgetLoading ? (
          <Flex
            justifyContent='center'
            alignItems='center'
          >
            <Spinner />
          </Flex>
        ) : (
          !getLSItem('engagement_id') && (
            <Flex
              wrap={'wrap'}
              justifyContent={'center'}
              gap={1}
              mx='-1'
            >
              {buttons.map((button, index) => (
                <Button
                  variant={index == 0 && isPrimaryBtnEnabled ? 'customer_option' : 'option'}
                  key={button.value}
                  aria-label={t('select_option')}
                  onClick={() => handleOptionSelect(button)}
                  ref={index === buttons.length - 1 ? lastButtonsRef : undefined}
                >
                  {button.title}
                </Button>
              ))}
              {isImageUploadEnabled && (
                <Button
                  variant={'option'}
                  aria-label={t('select_option')}
                  onClick={handleImageCapture}
                  ref={lastButtonsRef}
                >
                  {placeholder}
                </Button>
              )}
            </Flex>
          )
        )}
      </Container>
      <Footer
        toggleExpandCollapse={toggleExpandCollapse}
        onBack={handleBack}
        isTextInputDisabled={isTextInputDisabled || isLoading}
        onSend={sendMessage}
        placeholder={placeholder}
      />
    </Container>
  )
}
