import { MODE } from '../../api/dataService'
import { AgentMessageIcon, BotIcon, UserIcon } from '../styles/components/icons'

export const GetIcon: React.FC<{ from: (typeof MODE)[keyof typeof MODE] }> = ({ from }) => {
  switch (from) {
    case MODE.AGENT:
      return <AgentMessageIcon color={'widget.text'} />
    case MODE.BOT:
      return (
        <BotIcon
          boxSize={8}
          color='widget.botIcon'
        />
      )
    default:
      return <UserIcon />
  }
}
