import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'

import { render } from 'preact'
import { Suspense } from 'preact/compat'
import { Config } from './api/dataService'
import { App } from './App'
import defaultConfig from './configs/defaultConfig.json'
import { ConfigProvider } from './context/config'
import './index.css'
import { fetchConfig } from './utils/hooks/useConfig'
const LoadingFallback = () => <div>Loading translations...</div>
declare global {
  interface Window {
    initializeWidget: (config: Config) => void
  }
}

const getQueryParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

if (import.meta.env.VITE_INSTANCE_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_INSTANCE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const connectScript = (config: Config) => {
  const { scripts } = config

  if (scripts) {
    scripts.forEach((script) => {
      const script_ = document.createElement('script')
      script_.id = script.id
      script_.src = script.url

      if (script.attribute) {
        script_.setAttribute(script.attribute, script.attributeValue)
      }
      document.head.appendChild(script_)
    })
  }
}

const initializeWidget = async () => {
  const scriptTag = document.currentScript
  const configFromUrl = getQueryParam('config')
  const configUrl = configFromUrl ? configFromUrl : scriptTag?.getAttribute('data-config')

  if (configUrl) {
    const fetchedConfig: Config = await fetchConfig(configUrl)
    connectScript(fetchedConfig || defaultConfig)
    renderWidget(fetchedConfig || defaultConfig)
  } else {
    renderWidget(defaultConfig)
  }
}

initializeWidget()

function renderWidget(finalConfig: Config) {
  const container = document.getElementById('widget-container')
  if (container) {
    render(
      <ConfigProvider config={finalConfig}>
        <Suspense fallback={<LoadingFallback />}>
          <ErrorBoundary fallback={<p>Something went wrong. Please try again later.</p>}>
            <App />
          </ErrorBoundary>
        </Suspense>
      </ConfigProvider>,
      container
    )
  }
}
if (typeof window !== 'undefined') {
  window.initializeWidget = renderWidget
}
