import { useState } from 'preact/hooks'
import {
  BotMsgChoice,
  BotRequestData,
  BotResponse,
  choiceType,
  Config,
  EventTypes,
  isBasic,
  isRedirectionType,
  isSingleChoice,
  MessageTypes,
  MODE,
  nodeType,
  Payload,
} from '../../api/dataService'
import { sendMessageRequest } from '../../api/direct'
import { useButtonsContext } from '../../context/buttons'
import { useConfig } from '../../context/config'
import { useInputStateContext } from '../../context/inputState'
import { CarouselItems, Message, useMessagesContext } from '../../context/messages'
import { getLSItem, removeLSItem, setLSItem } from '../../utils/localStorage'
import { ANALYTICS_EVENTS } from '../analytics.interface'
import { sendAnalyticsData } from './useAnalytics'

export const useBot = () => {
  const { addMessage } = useMessagesContext()
  const { addButtons, setIsPrimaryBtnEnabled } = useButtonsContext()
  const {
    apiConfig: { botURL, botId, authHost },
  }: Config = useConfig()
  const config: Record<string, any> = useConfig()
  const { setIsTextInputDisabled } = useInputStateContext()

  const [isImageUploadEnabled, setIsImageUploadEnabled] = useState<boolean>(
    !!getLSItem<boolean>('is_image_upload_enabled')
  )
  const [placeholder, setPlaceholder] = useState<string>(getLSItem('placeholder') || '')
  const [isAgentTriggered, setIsAgentTriggered] = useState(false)
  const hasQueryParams = (url: string) => {
    return new URL(url).searchParams.toString().length > 0
  }
  const queryParams = new URLSearchParams(window.location.search)

  const sendMessage = async (
    reqData: BotRequestData,
    { isMessageShown } = { isMessageShown: false }
  ) => {
    setLSItem('botId', queryParams.get('botId') || botId)
    addButtons([])
    if (
      isMessageShown &&
      reqData.text &&
      (reqData.type === MessageTypes.TEXT || reqData.type === MessageTypes.QUICK_REPLY)
    ) {
      addMessage([{ text: reqData.text, type: reqData.type, from: MODE.USER }])
    }
    collectAnalytics({ msg: reqData.text })
    const res: BotResponse[] | undefined = await sendMessageRequest(
      reqData,
      queryParams.get('botId') || botId,
      botURL,
      authHost
    )

    if (!res) {
      return
    }
    const botResponse = res[res.length - 1]
    if (botResponse?.node === nodeType.CONNECT_AGENT) {
      setIsAgentTriggered(true)
    }

    setInput(res)
    const botResponses = res.filter(isBasic).map((r) => {
      const isCarouselType = r.type === choiceType.CAROUSEL
      if (isCarouselType) {
        const response: BotResponse & CarouselItems = r
        return {
          items: response.items,
          from: MODE.BOT,
          type: MessageTypes.CAROUSEL,
          carouselType: response.carouselType,
        }
      } else if (r.type === choiceType.IMAGE) {
        return {
          image: r.image,
          title: r.title,
          type: MessageTypes.IMAGE,
          from: MODE.BOT,
        }
      } else {
        return {
          text: r.text,
          type: MessageTypes.TEXT,
          from: MODE.BOT,
        }
      }
    })
    collectAnalytics({ botResponses })
    addMessage([...botResponses])
    addButtons(res.filter(isSingleChoice).flatMap(({ choices }: BotMsgChoice) => choices))

    res.filter(isRedirectionType).forEach(({ url, type }) => {
      if (!url) return
      const isOffPageReference = type === choiceType.OFF_PAGE_REFERENCE
      if (isOffPageReference) {
        setTimeout(() => {
          window.open(url, '_blank')
        }, config.timeoutsAndDelays.redirectionDelayTime)
      } else {
        window.location.href = `${url}${hasQueryParams(url) ? '&' : '?'}showBot=true`
      }
    })
  }

  const collectAnalytics = ({ msg, botResponses }: { msg?: string; botResponses?: Message[] }) => {
    if (msg) {
      sendAnalyticsData({
        event_category: ANALYTICS_EVENTS.MESSAGE,
        from: MODE.USER,
        to: MODE.BOT,
      })
    }

    botResponses?.forEach((response) => {
      if (response.type === MessageTypes.TEXT)
        sendAnalyticsData({
          event_category: ANALYTICS_EVENTS.MESSAGE,
          from: MODE.BOT,
          to: MODE.USER,
        })
    })
  }

  const setInput = (res: BotResponse[]) => {
    const disableFreeText = !!res[res.length - 1]?.disableFreeText
    const placeholder = res[res.length - 1]?.placeholder || ''
    const isPrimary = !!res[res.length - 1]?.primaryCTA
    const isImageUploadEnabled = res[res.length - 1]?.nodeType === nodeType.IMG_UPLOAD

    setIsImageUploadEnabled(isImageUploadEnabled)
    setIsTextInputDisabled(disableFreeText)
    setPlaceholder(placeholder)
    setLSItem('placeholder', placeholder)
    setIsPrimaryBtnEnabled(isPrimary)
    setLSItem('is_input_disabled', disableFreeText)
    setLSItem('is_image_upload_enabled', isImageUploadEnabled)
  }

  const changeBotLanguage = async (lang: string) => {
    const userId = getLSItem('user_id')
    removeLSItem('messages')
    removeLSItem('buttons')
    removeLSItem('placeholder')
    addButtons([])
    addMessage([])
    setLSItem('language', lang)
    if (userId) {
      const payload: Payload = { language: lang as 'es' | 'en', channel: 'web' }
      await sendMessage({ type: EventTypes.PROACTIVE_TRIGGER, payload })
    }
  }

  return {
    sendMessage,
    placeholder,
    isAgentTriggered,
    setIsAgentTriggered,
    isImageUploadEnabled,
    changeBotLanguage,
  }
}
