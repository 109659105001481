import { MODE } from '../api/dataService'

export const ANALYTICS_EVENTS = {
  EVENT: 'UIaaS',
  BUTTON_CLICK: 'button click',
  LINK_CLICK: 'link click',
  CONNECT_TO_AGENT: 'connect to agent',
  RESET: 'reset',
  QUIT_FROM_USER: 'agent quit form user',
  QUIT_FROM_AGENT: 'agent close converation',
  AGENT_UNAVAILABLE: 'agents are unavailable',
  UNAVAILABLE_CONNECT_AGENT: 'unable to connect with an agent',
  CLOSE_WIDGET: 'close widget',
  OPEN_WIDGET: 'open widget',
  MESSAGE: 'Message',
}

type LinkClickEvent = {
  event_category: typeof ANALYTICS_EVENTS.LINK_CLICK
  url: string
}

type AnalyticsBaseEvt = {
  event_category: typeof ANALYTICS_EVENTS.MESSAGE
  from: (typeof MODE)[keyof typeof MODE]
  to: (typeof MODE)[keyof typeof MODE]
}

type AnalyticsUserToAgentEvt = {
  from: typeof MODE.USER
  to: typeof MODE.AGENT
  engagementId: string
}
type AnalyticsAgentToUser = {
  from: typeof MODE.AGENT
  to: typeof MODE.USER
  engagementId: string
}

type AnalyticsUserToBotEvt = {
  from: typeof MODE.USER
  to: typeof MODE.BOT
  engagementId: string
}

type AnalyticsBotToUserEvt = {
  from: typeof MODE.BOT
  to: typeof MODE.USER
  engagementId: string
}

// Require engagementId only if from or to is MODE.AGENT
type AnalyticsMessageEvt = AnalyticsBaseEvt &
  (AnalyticsUserToAgentEvt | AnalyticsAgentToUser | AnalyticsUserToBotEvt | AnalyticsBotToUserEvt)

type ButtonClickEvent = {
  event_category: typeof ANALYTICS_EVENTS.BUTTON_CLICK
  name: string
}
type ConnectToAgentEvent = {
  event_category: typeof ANALYTICS_EVENTS.CONNECT_TO_AGENT
  engagementId: string
  message: string
}
type ErrorEvent = {
  event_category:
    | typeof ANALYTICS_EVENTS.AGENT_UNAVAILABLE
    | typeof ANALYTICS_EVENTS.UNAVAILABLE_CONNECT_AGENT
  statusCode: number
}

export type Analytics =
  | { event_category: (typeof ANALYTICS_EVENTS)[keyof typeof ANALYTICS_EVENTS] }
  | LinkClickEvent
  | AnalyticsMessageEvt
  | ErrorEvent
  | ButtonClickEvent
  | ConnectToAgentEvent
