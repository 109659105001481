import { createContext, useContext } from 'preact/compat'

const ConfigContext = createContext(null)

export const ConfigProvider = ({ config, children }: { config: any; children: any }) => {
  if (!config?.apiConfig?.botId) {
    throw new Error(
      'ConfigProvider Error: Missing "botId" in config.apiConfig. Please ensure your configuration includes a valid botId.'
    )
  }

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}
