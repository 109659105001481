export const captureImage = async (sendPhoto: (base64: string) => Promise<void>) => {
  try {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = async (event) => {
      try {
        const target = event.target as HTMLInputElement
        const file = target.files ? target.files[0] : null
        if (!file) return

        const reader = new FileReader()
        reader.onloadend = async () => {
          try {
            const base64 = (reader.result as string).split(',')[1]
            await sendPhoto(base64)
          } catch (error) {
            console.error('Error sending photo:', error)
          }
        }
        reader.readAsDataURL(file)
      } catch (error) {
        console.error('Error reading file:', error)
      }
    }
    input.click()
  } catch (error) {
    console.error('Error capturing image:', error)
  }
}
