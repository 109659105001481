import { extendTheme } from '@chakra-ui/react'
import { Config } from '../../api/dataService'
import { useConfig } from '../../context/config'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { Container } from './components/container'
import { Drawer } from './components/drawer'
import { Popover } from './components/popover'
import { Text } from './components/text'

export const useDynamicTheme = () => {
  const config: Config = useConfig()
  const {
    theme: { colors, sizes, logo, borders, radii, space, fonts, fontSizes, specificFonts },
  } = config

  if (fonts?.regular && fonts?.bold) {
    // Preload fonts dynamically
    const preloadFont = (url: string) => {
      if (!document.querySelector(`link[href="${url}"]`)) {
        const link = document.createElement('link')
        link.rel = 'preload'
        link.href = url
        link.as = 'font'

        // Detect file extension for correct type
        const extension = url.split('.').at(-1) || ''
        if (['otf', 'ttf'].includes(extension)) {
          link.type = `font/${extension}`
        }

        link.crossOrigin = 'anonymous'
        document.head.appendChild(link)
      }
    }
    preloadFont(fonts.regular)
    preloadFont(fonts.bold)
    if (fonts.italic) preloadFont(fonts.italic)
    const style = document.createElement('style')
    style.id = 'WidgetFont'
    // Create dynamic font paths based on brand
    const regularFontPath = fonts.regular
    const boldFontPath = fonts.bold
    const italicFontPath = fonts.italic
    const fontSize = fontSizes.generalFontSize

    // Use font-display: fallback to ensure a temporary font is shown instead of blank space
    style.innerHTML = `
      @font-face {
        font-family: 'WidgetRegular';
        src: url('${regularFontPath}') format('truetype');
        font-weight: normal;
         font-display: fallback;
      }

      @font-face {
        font-family: 'WidgetBold';
        src: url('${boldFontPath}') format('truetype');
        font-weight: bold;
         font-display: fallback;
      }

      @font-face {
        font-family: 'WidgetItalic';
        src: url('${italicFontPath}') format('truetype');
        font-style: italic;
         font-display: fallback;
      }

      #chat_widget_app * {
        font-family: WidgetRegular;
        font-size: ${fontSize}
      }

      #chat_widget_app * strong,
      #chat_widget_app * b,
      #chat_widget_app * h1,
      #chat_widget_app * h2,
      #chat_widget_app * h3,
      #chat_widget_app * h4,
      #chat_widget_app * h5,
      #chat_widget_app * h6,
      #chat_widget_app * [style*="font-weight: bold"],
      #chat_widget_app * [style*="font-weight: 700"],
      #chat_widget_app * [style*="font-weight: 600"],
      #chat_widget_app h1 *,
      #chat_widget_app h2 *,
      #chat_widget_app h3 *,
      #chat_widget_app h4 *,
      #chat_widget_app h5 *,
      #chat_widget_app h6 * {
        font-family: WidgetBold  !important;
      }

      #chat_widget_app * em,
      #chat_widget_app * i {
        font-family: 'WidgetItalic' !important;
        font-style: italic;
      }

      #chat_widget_app textarea {
      font-size: ${fontSizes.placeholderSize}
      }
    `
    document.head.appendChild(style)
  }

  return extendTheme({
    styles: {
      global: {
        'html, body': {
          background: 'transparent',
        },
        // TODO: A temporary solution
        '#chat_widget_app .chakra-modal__content-container': {
          position: 'fixed',
          bottom: '0',
          right: '0',
          width: 'auto !important',
          height: 'auto !important',
          left: 'unset !important',
          top: 'unset !important',
          zIndex: 9948030,
        },
      },
    },
    logo: {
      widget: {
        ...logo,
      },
    },
    colors: {
      widget: {
        gray: {
          100: '#F2F2F2',
          200: '#E2E2E2',
          300: '#C2C2C2',
          400: '#F5F5F5',
          500: '#747676',
          700: '#474a4d',
          800: '#D9DBDB',
          900: '#333333',
        },
        red: {
          100: '#EE0000',
        },

        ...colors,
      },
    },
    sizes: {
      widget: {
        heightWidget: '80%',
        heightWidgetMobile: '550px',
        widthWidget: '360px',
        ...sizes,
      },
    },
    fontSizes: {
      widget: {
        sm: '14px',
        md: '16px',
        lg: '20px',
        ...fontSizes,
      },
    },
    radii: {
      widget: {
        ...radii,
      },
    },
    borders: {
      widget: {
        ...borders,
      },
    },
    space: {
      widget: {
        ...space,
      },
    },
    fonts: {
      widget: {
        ...specificFonts,
      },
    },
    components: {
      Button,
      Container,
      Text,
      Drawer,
      Badge,
      Popover,
    },
  })
}
